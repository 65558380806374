import React from "react"
import SEO from "../components/seo"
import FeedbackForm from "../components/form/feedbackForm"

const Audit = ({ location }) => {
  return (
    <>
      <SEO
        titleOverride={"Feedback"}
        pathnameOverride={location.pathname}
      />

      <section className="mb-16 lg:mb-32">
        <div className="container">
          <div className="w-full md:w-2/3 text-center mx-auto mb-8 md:mb-0">
            <h1>Feedback</h1>
            <p className="text-lg mb-8 md:mb-16">We strive to provide great customer service and we want to know how we measured up. Use the form below and send us your feedback directly to our customer services team.</p>
          </div>
        </div>
        <div className="w-full md:w-8/12 xl:w-1/2 mx-auto">
          <FeedbackForm />
        </div>
      </section>
    </>
  )
}

export default Audit